<template>
    <div>
        <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#4480C5"
        size="60"
        is-full-screen
      />
        <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: #ffffff">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: #ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>

        <v-layout wrap justify-center>
            <v-flex pt-5 xs11 sm11 md11 lg11 xl11>

                <!-- Header Section -->
                <v-layout wrap justify-start class="my-3">
                    <v-flex xs12 sm3 md3 lg10 text-start align-center pt-2 class="headingChurch">About us</v-flex>
                    <!-- <v-flex xs12 sm9 md9 lg2 text-end align-center><v-btn class="churchbtn" color="blue"
                            style="color:white" @click="dialog = true">ADD ABOUT US</v-btn></v-flex> -->

                            <v-flex xs12 sm9 md9 lg2 text-end align-center v-if="userRole == 'admin'"><v-btn class="churchbtn" color="blue"
                                style="color:white" @click="editdialog = true">EDIT ABOUT US</v-btn></v-flex>


                </v-layout>
                <v-dialog v-model="dialog" max-width="800px">
                    <v-card rounded="lg">
                        <v-card-title>
                            <span class="editbox">ADD ABOUT US</span>
                            <v-spacer></v-spacer>
                            <v-btn color="red" icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>
                            <v-form>
                                <v-layout wrap justify-center px-2>
                                    <v-flex xs12 pr-2>
                                        <span class="label ">
                                            Title
                                        </span>
                                        <v-text-field class="pt-2 text-des" style="font-size:14px" v-model="title"
                                            outlined dense hide-details></v-text-field>
                                    </v-flex>

                                    <v-flex xs12 pr-2 pt-3>
                                        <span class="label ">
                                            Description
                                        </span>
                                        <vue-editor class="pt-2 text-des" style="font-size:14px" v-model="description"></vue-editor>
                                    </v-flex>


                                   
                                </v-layout>
                            </v-form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions class="py-4 justify-end headline grey lighten-2">
                            <v-btn outlined color="grey" text @click="dialog = false"><span
                                    style="color: black;">Cancel</span> </v-btn>
                            <v-btn color="primary" @click="itemadd()">Save Changes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- table Section -->

                <v-card class="custom-card">
                    <v-card-title class="card-header">
                        <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-layout wrap justify-center py-3>
                                <!-- <v-flex xs4 px-4>
                                  <v-img v-bind:src="mediaUURL + miracleView.photo" cover ></v-img>

                                </v-flex> -->
                                <v-flex xs12>
                                  <v-layout wrap justify-center>
                                    <v-flex x12 text-center pt-3>
                                      <span 
                                      :style="{
                                       
                                        'overflow-wrap': 'break-word',
                                        'word-break': 'break-word',
                                       
                                      }"
                                      
                                      style="
                                          font-family: interbold;
                                          color: #1C6DC1;
                                          font-size: 20px;
                                        ">{{
                                            aboutlist.title
                                            }}</span><br>
                      
                                      
                                    </v-flex>
                      
                      
                      
                                    <v-flex xs12 px-4 pt-3 text-justify>
                                      <span style="font-family:interregular; text-align: justify; font-size: 16px;" v-html="aboutlist.description"></span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                            </v-layout>
                            </v-flex>
                          </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
      
      
      
                    <!-- Custom HTML Table -->
                  
      
                  </v-card>
                
            </v-flex>
        </v-layout>


        <!--edit dialog  -->
        

        <v-dialog v-model="editdialog" max-width="800px">
            <v-card rounded="lg">
                <v-card-title>
                    <span class="editbox">EDIT ABOUT US</span>
                    <v-spacer></v-spacer>
                    <v-btn color="red" icon @click="editdialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-layout wrap justify-center px-2>
                            <v-flex xs12 pr-2>
                                <span class="label ">
                                    Title
                                </span>
                                <v-text-field class="pt-2 text-des" style="font-size:14px" v-model="aboutlist.title"
                                    outlined dense hide-details></v-text-field>
                            </v-flex>

                            <v-flex xs12 pr-2 pt-3>
                                <span class="label ">
                                    Description
                                </span>
                                <vue-editor class="pt-2 text-des" style="font-size:14px" v-model="aboutlist.description"></vue-editor>
                            </v-flex>


                           
                        </v-layout>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="py-4 justify-end headline grey lighten-2">
                    <v-btn outlined color="grey" text @click="editdialog = false"><span
                            style="color: black;">Cancel</span> </v-btn>
                    <v-btn color="primary" @click.prevent="validateInput">Save Changes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

         <!-- delete dialog -->

         <v-dialog v-model="deletedialog" max-width="600">
            <v-card class="dialog-card">
                <v-card-title class="grey lighten-2 d-flex justify-center">
                    <v-icon color="red" size="32">mdi-alert</v-icon>
                    <span class="ml-2">Confirm Deletion</span>
                </v-card-title>
                <v-card-text class="py-5 text-center text-des">
                    <div class="body-1">
                        Are you sure you want to delete this Country?
                        <br />
                        This action <strong>cannot be undone</strong>.
                    </div>
                    <v-divider class="my-3"></v-divider>
                    <v-row class="pt-2" no-gutters>
                        <v-col>
                            <img src="@/assets/images/countryb.png" width="40px" height="40px" class="dialog-icon" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pa-2">
                    <v-btn style="font-family: interregular; font-size: 13px; color: white;" color="#cf3a45"
                        @click="deletedialog = false">Cancel</v-btn>
                    <v-btn style="font-family: interregular; font-size: 13px; color: white;" color="blue darken-1"
                        @click="confirmDelete">OK</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>


    </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor'; 

export default {
    components: {
VueEditor
},
    data() {
        return {
            userRole: localStorage.getItem("role"),
            showSnackBar:false,
      timeout:5000,
      msg: "",
            deletedialog: false,
            dialog: false,
            description: null,
            title:"",
            appLoading: false,
           aboutlist: {},
            editdialog: false,
            editingitem: [],
            headers: [
                { text: "Name", value: "name", width: "500px" },

                { text: "Actions", value: "_id", width: "5px", }

            ],

        };
    },
    mounted() {
        this.getData(); // Fetch data when component mounts
    },
    methods: {

        itemadd() {
            var data = {};
            data["title"] = this.title;
            data["description"] = this.description;
           
            axios({
                url: "/add/aboutus",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.message;
                        this.showSnackBar = true;
                        this.dialog = false;
                        this.name = null;
                        
                        // this.getData();
                    } else {
                        this.msg = response.data.message;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
       
        getData() {
            this.appLoading = true;
            axios({
                url: "/get/aboutus",

                method: "GET",
                headers: {
                    token: localStorage.getItem("token"),
                },
                params: {
                    //  count: this.count,
                    // page: this.currentPage,

                },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.aboutlist = response.data.data;
                    // this.pages = response.data.pages;
                    // this.totalData = response.data.count;
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },

        
        validateInput(event) {
  event.preventDefault();

  const titlePattern = /^[A-Za-z\s]+$/;
  

  if (!this.$route.query.id && !this.aboutlist.title) {
    this.msg = "Please Provide Title";
    this.showSnackBar = true;
    return;
  } else if (!titlePattern.test(this.aboutlist.title)) {
    this.msg = "Title can only contain alphabets";
    this.showSnackBar = true;
    return;
  } else if (!this.aboutlist.description) {
    this.msg = "Please Provide Description";
    this.showSnackBar = true;
    return;
  } else {
    this.editAbout();
  }
},


      editAbout() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/edit/aboutus",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // heading: this.heading,

        title: this.aboutlist.title,
        description: this.aboutlist.description,
        
          id: this.aboutlist._id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
             this.showSnackBar = true;
            // this.msg = "Updated Successfully";
            // this.uploadImage();
            this.editdialog = false;
            if (this.imageEdited) {
              this.uploadImage();
            }
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          // store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },

        openDeleteDialog(item) {
            this.itemToDelete = item;
            this.deletedialog = true;
        },
        confirmDelete() {
            if (this.itemToDelete) {
                this.deleteItem(this.itemToDelete);
            }
            this.deletedialog = false;
        },
        deleteItem(r) {
            var data = {};
            data["id"] = r._id;
            axios({
                url: "/delete/country",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.delete = false;
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.message;
                        this.showSnackBar = true;
                        this.getData();
                    } else {
                        this.msg = response.data.message;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },

    }
};
</script>

<style scoped>


.dialog-card {
    font-family: interbold;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dialog-icon {
    animation: pulse 1s infinite alternate;
}

.dialog-button {
    min-width: 120px;
}

@keyframes pulse {
    from {
        transform: scale(1);
        opacity: 0.7;
    }

    to {
        transform: scale(1.1);
        opacity: 1;
    }
}
.table-responsive {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
    background-color: white;
    table-layout: fixed; /* Fixes column widths to prevent stretching */
  }
  
  .table th,
  .table td {
    padding: 12px;
    text-align: left;
  }
  
  .table th {
    background-color: white;
    font-family: interbold;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
  
  .table td {
    font-family: interregular;
    font-size: 15px;
  }
  
  .table-row:hover {
    background-color: #eff2f6;
  }
  
  .actions-column {
    text-align: center; /* Center-aligns the action buttons */
  }
  
</style>